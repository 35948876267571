import { gql } from '@apollo/client'
import * as fragments from '../fragments'

export const GET_HELP_REQUEST_LIST = gql`
    query GetHelpRequest(
        $first: Int
        $afterCursor: Cursor
        $eventId: ObjectID!
    ) {
        helpRequests(
            first: $first
            after: $afterCursor
            filter: { event: $eventId }
        ) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    ...HelpRequestData
                }
            }
        }
    }

    ${fragments.HelpRequestData}
`

export const GET_HELP_REQUEST_ORDERABLE = gql`
    query GetHelpRequests(
        $first: Int
        $afterCursor: Cursor
        $eventId: ObjectID!
        $orderBy: HelpRequestOrderBy
        $search: String
        $name: Filter
        $email: Filter
        $status: HelpRequestStatus
    ) {
        helpRequests(
            first: $first
            after: $afterCursor
            filter: {
                event: $eventId
                name: $name
                email: $email
                status: $status
            }
            search: $search
            orderBy: $orderBy
        ) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    ...HelpRequestData
                }
            }
        }
    }

    ${fragments.HelpRequestData}
`

export const GET_HELP_REQUEST = gql`
    query GetHelpRequest($id: ObjectID!) {
        helpRequest(id: $id) {
            ...HelpRequestData
        }
    }

    ${fragments.HelpRequestData}
`

