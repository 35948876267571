import { gql } from '@apollo/client'
import * as fragments from '../fragments'

export const GET_TICKET_OFFER_LIST_ORDENABLE = gql`
    query GetTicketOffer(
        $first: Int
        $afterCursor: Cursor
        $orderBy: TicketOfferOrderBy
        $eventId: ObjectID!
        $search: String
        $slug: Filter
    ) {
        ticketOffers(
            first: $first
            after: $afterCursor
            orderBy: $orderBy
            search: $search
            filter: { event: $eventId, slug: $slug }
        ) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    ...TicketOfferData
                }
            }
        }
    }
    ${fragments.TicketOfferData}
`

export const GET_TICKET_OFFER = gql`
    query GetTicketOffer($id: ObjectID!) {
        ticketOffer(id: $id) {
            ...TicketOfferData
        }
    }

    ${fragments.TicketOfferData}
`

