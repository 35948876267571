import React from 'react'
import { message, Form, Input, Select } from 'antd'
import { useApolloClient } from '@apollo/client'

import { CrudModal, useCrudForm } from '@cms/core/components/Crud'

import { UPDATE_HELP_REQUEST } from '@cms/events/graphql/mutations/helpRequest'
import { GET_HELP_REQUEST } from '@cms/events/graphql/queries/helpRequest'

const HelpRequestModal = ({ params, ...props }) => {
    const { eventId } = params

    const client = useApolloClient()

    const [{ form, isEdit, getError }, modalProps] = useCrudForm({
        getData: async (id) => {
            if (!id) return

            const { data } = await client.query({
                query: GET_HELP_REQUEST,
                variables: { id }
            })

            return data.helpRequest
        },
        createData: async (formData) => {
            message.success('Local cadastrado com sucesso!')
        },
        updateData: async (id, formData) => {
            await client.mutate({
                mutation: UPDATE_HELP_REQUEST,
                variables: { id, ...formData, event: eventId }
            })

            message.success('Chamado atualizado com sucesso!')
        }
    })

    return (
        <CrudModal
            title={isEdit ? 'Editar Chamado' : 'Novo Chamado'}
            {...modalProps}
            {...props}
        >
            <Form layout={'vertical'} form={form}>
                <Form.Item label="Nome" name="name" {...getError('name')}>
                    <Input disabled />
                </Form.Item>

                <Form.Item label="E-mail" name="email" {...getError('email')}>
                    <Input disabled />
                </Form.Item>

                <Form.Item
                    label="Descrição"
                    name="description"
                    {...getError('description')}
                >
                    <Input.TextArea disabled />
                </Form.Item>

                <Form.Item label="Status" name="status">
                    <Select>
                        <Select.Option value="PENDING">Pendente</Select.Option>
                        <Select.Option value="SOLVED">Resolvido</Select.Option>
                    </Select>
                </Form.Item>
            </Form>
        </CrudModal>
    )
}

export default HelpRequestModal

