export const BASE_URL = `/evento/:eventId/checkout/config`

import { SECTIONS_DEFAULT } from '@cms/core/components/ThemeBuilder/pages/Theme/constants'

export const ROUTES = [
    {
        path: `tema`,
        name: 'Theme',
        home: true,
        exact: true,
        title: 'Tema',
        subTitle: 'Configuração de cores da página de checkout',
        sections: SECTIONS_DEFAULT.filter(
            (section) => section.name !== 'sponsor'
        )
    },
    {
        path: `images`,
        name: 'Images',
        exact: true,
        title: 'Imagens',
        subTitle: 'Imagens de fundo',
        sections: [
            {
                name: 'backgroundImage',
                label: 'Imagem de fundo'
            },
            {
                name: 'loginBackgroundImage',
                label: 'Imagem de fundo - Login'
            }
        ]
    },
    {
        path: 'checkoutConfig.scripts',
        name: 'Scripts',
        exact: true,
        title: 'Scripts'
    },
    {
        path: `configs`,
        name: 'Configs',
        exact: true,
        title: 'Configurações',
        subTitle: 'Configurações do checkout',
        sections: [
            {
                title: 'Geral',
                name: 'general',
                fields: [
                    {
                        name: 'redirectUrl',
                        label: 'Url de Redirecionamento',
                        component: 'input'
                    },
                    {
                        name: 'infos',
                        label: 'Informações',
                        component: 'html'
                    }
                ]
            }
        ]
    }
]

