import React from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { useParams } from 'react-router-dom'

import { useCreation } from '@umijs/hooks'

import {
    CrudList,
    CreateModalRoutes,
    CrudColumns
} from '@cms/core/components/Crud'

import { GET_TICKET_OFFER_LIST_ORDENABLE } from '@cms/events/graphql/queries/ticketOffers'
import { DELETE_TICKET_OFFER } from '@cms/events/graphql/mutations/tickerOffers'

import TicketOfferModal from './TicketOfferModal'

import dayjs from 'dayjs'

const columns = [
    {
        title: 'Nome',
        dataIndex: ['node', 'name']
    },
    {
        title: 'Data de criação',
        dataIndex: ['node', 'createdAt'],
        sorter: 'createdAt',
        render: (_value, obj) => {
            return (
                <CrudColumns
                    onlyText
                    text={[dayjs(_value).format('DD/MM/YYYY [-] H:mm')]}
                />
            )
        }
    }
]

const TicketOfferList = () => {
    const { eventId } = useParams()

    const variables = { first: 25, eventId, orderBy: { createdAt: 'DESC' } }

    const path = `/evento/${eventId}/ofertas`

    const query = useQuery(GET_TICKET_OFFER_LIST_ORDENABLE, {
        variables
    })

    const [deleteData] = useMutation(DELETE_TICKET_OFFER, {
        refetchQueries: [{ query: GET_TICKET_OFFER_LIST_ORDENABLE, variables }]
    })

    const ModalRoutes = useCreation(() => {
        return CreateModalRoutes(TicketOfferModal, path, { eventId })
    }, [eventId])

    if (query.error) return <div>Error: {query.error}</div>

    const filterFields = [{ name: 'slug', label: 'Slug', queryType: 'Filter' }]

    return (
        <>
            <ModalRoutes />
            <CrudList
                header={{
                    title: 'Ofertas',
                    subTitle: 'Adicione e gerencie as ofertas do evento',
                    buttons: [
                        {
                            children: 'Nova Oferta',
                            type: 'primary',
                            link: path + '/adicionar'
                        }
                    ]
                }}
                search={true}
                columns={columns}
                queryName={'ticketOffers'}
                onDelete={deleteData}
                filterFields={filterFields}
                showHeader
                {...query}
            />
        </>
    )
}

export default TicketOfferList

