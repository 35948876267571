import React from 'react'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import {
    CrudList,
    CrudColumns,
    CreateModalRoutes
} from '@cms/core/components/Crud'

import dayjs from 'dayjs'
import { GET_HELP_REQUEST_ORDERABLE } from '@cms/events/graphql/queries/helpRequest'
import { useCreation } from '@umijs/hooks'
import HelpRequestModal from './HelpRequestModal'

const statusMap = {
    PENDING: 'Pendente',
    SOLVED: 'Resolvido'
}

const columns = [
    {
        title: 'Nome',
        sorter: 'name',
        dataIndex: ['node', 'name']
    },
    {
        title: 'E-mail',
        sorter: 'email',
        dataIndex: ['node', 'email']
    },
    {
        title: 'Status',
        sorter: 'status',
        dataIndex: ['node', 'status'],
        render: (_value, obj) => {
            return <CrudColumns onlyText text={[statusMap[_value] || '']} />
        }
    },
    {
        title: 'Data de criação',
        dataIndex: ['node', 'createdAt'],
        sorter: 'createdAt',
        render: (_value, obj) => {
            return (
                <CrudColumns
                    onlyText
                    text={[dayjs(_value).format('DD/MM/YYYY [-] H:mm')]}
                />
            )
        }
    }
]

const HelpRequestList = () => {
    const { eventId } = useParams()

    const variables = { first: 25, eventId }

    const path = `/evento/${eventId}/faq/chamados`

    const query = useQuery(GET_HELP_REQUEST_ORDERABLE, {
        variables
    })

    const ModalRoutes = useCreation(() => {
        return CreateModalRoutes(HelpRequestModal, path, { eventId })
    }, [eventId])

    if (query.error) return <div>Error: {query.error}</div>

    const filterFields = [
        { name: 'name', label: 'Nome', queryType: 'Filter' },
        { name: 'email', label: 'E-mail', queryType: 'Filter' },
        {
            name: 'status',
            label: 'Status',
            options: [
                { label: 'Pendente', value: 'PENDING' },
                { label: 'Resolvido', value: 'SOLVED' }
            ]
        }
    ]

    return (
        <>
            <ModalRoutes />
            <CrudList
                header={{
                    title: 'Chamados',
                    subTitle: 'Gerencie os chamados do evento'
                }}
                search={true}
                columns={columns}
                queryName={'helpRequests'}
                showHeader
                filterFields={filterFields}
                hideAction="delete"
                {...query}
            />
        </>
    )
}

export default HelpRequestList

