import { gql } from '@apollo/client'
import * as fragments from '../fragments'

export const UPDATE_HELP_REQUEST = gql`
    mutation UpdateHelpRequest(
        $id: ObjectID!
        $event: ObjectID!
        $name: String!
        $email: String!
        $description: String!
        $status: HelpRequestStatus
    ) {
        updateHelpRequest(
            id: $id
            input: {
                event: $event
                name: $name
                email: $email
                description: $description
                status: $status
            }
        ) {
            ...HelpRequestData
        }
    }

    ${fragments.HelpRequestData}
`

